import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'

import '../styles/header.scss'

// import SVG from './svg'
import GithubIcon from '../icons/github-icon'
import LinkedInIcon from '../icons/linked-in-icon'
import BurgerIcon from '../../../assets/svgs/icons/ic-menu.svg'

const Header = ({ siteTitle }) => {
  const headerRef = useRef(null)
  const [fixedHeader, setFixedHeader] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <header className={`container header ${fixedHeader ? 'fixed' : ''}`} ref={headerRef}>
      <nav className="navbar">
        <Link className="nav-item logo" to="/">
          <span className="first-letter">H</span>ans &nbsp;
          <span className="first-letter">H</span>
          anken
        </Link>

        {/* TODO: Make this part dynamic based on screen size */}
        <div className="center-nav">
          <a className="nav-item" href="/#about">
            <span className="first-letter">A</span>bout
          </a>
          <a className="nav-item" href="/#work">
            <span className="first-letter">W</span>ork
          </a>
          <a className="nav-item" href="/#contact">
            <span className="first-letter">C</span>ontact
          </a>
        </div>

        <div className="right-nav">
          <a href="https://github.com/hanshank" target="_blank" rel="noopener noreferrer">
            <GithubIcon cssClass="nav-icon" />
          </a>
          <a href="https://www.linkedin.com/in/hans-martin-hanken/" target="_blank" rel="noopener noreferrer">
            <LinkedInIcon cssClass="nav-icon" wrapperCssClass="nav-item" />
          </a>
        </div>
        {/* Mobil navigation */}
        <BurgerIcon className="burger-icon" onClick={() => setMenuOpen(true)} />

        <div className={`mobil-nav ${menuOpen ? 'open' : ''}`}>
          <div className="mobil-nav-backdrop" onClick={() => setMenuOpen(false)} />
          <div className="mobil-nav-content">
            <a className="nav-item" href="/#about" onClick={() => setMenuOpen(false)}>
              <span className="first-letter">A</span>bout
            </a>
            <a className="nav-item" href="/#work" onClick={() => setMenuOpen(false)}>
              <span className="first-letter">W</span>ork
            </a>
            <a className="nav-item" href="/#contact" onClick={() => setMenuOpen(false)}>
              <span className="first-letter">C</span>ontact
            </a>
            <a
              href="https://github.com/hanshank"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-item"
              onClick={() => setMenuOpen(false)}
            >
              <GithubIcon cssClass="nav-icon" />
            </a>
            <a
              href="https://www.linkedin.com/in/hans-martin-hanken/"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-item"
              onClick={() => setMenuOpen(false)}
            >
              <LinkedInIcon cssClass="nav-icon" wrapperCssClass="nav-item" />
            </a>
          </div>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
