import React from 'react'
import PropTypes from 'prop-types'

// Style imports
import './styles/footer.css'

// Media imports
import Divider from '../../../assets/svgs/dividers/footer-top.svg'

const Footer = props => (
  <footer className="footer">
    <Divider />
    <div className="footer-content">
      <p className="text light-text center-text">© 2019 Hans Martin Hanken</p>
    </div>
  </footer>
)

Footer.propTypes = {}

export default Footer
