/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll'

import Header from './shared/header'
import Footer from './shared/footer'
import './layout.css'

import { GlobalStateContext, GlobalDispatchContext } from './context/global-context-provider'

// Library imports
import transitionAnimations from '../utils/transition-animations'

const Layout = ({ children }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const globalState = useContext(GlobalStateContext)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      allMarkdownRemark {
        edges {
          node {
            frontmatter {
              title
              slug
              projectUrl
              companyLogo
            }
          }
        }
      }
    }
  `)
  useEffect(() => {
    // Run transition animations
    transitionAnimations()
    // Ensures all anchor tags will be smooth scrolled to
    if (typeof window !== 'undefined') {
      require('smooth-scroll')('a[href*="#"]', {
        speed: 800,
        speedAsDuration: true,
        easing: 'easeInOutCubic',
      })
      if (window.location.hash) {
        scrollTo(window.location.hash)
      }
    }

    // Set Projects in global state
    const projects = data.allMarkdownRemark.edges
    dispatch({ type: 'SET_PROJECTS', data: projects })

    if (globalState.projectSlugs.history.length < 1) {
      // Set list of project slugs in global state
      const projectSlugs = projects.map(p => `/projects/${p.node.frontmatter.slug}`)
      dispatch({ type: 'SET_PROJECT_SLUGS', data: projectSlugs })
    }
  }, [data])

  return (
    <>
      <Header />

      <main>{children}</main>

      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
