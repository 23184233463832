import { throttle, debounce } from 'throttle-debounce'

// The default animation speed if none is passed in config
const defaultConfig = {
  load: {
    speed: 500,
  },
  scroll: {
    speed: 500,
  },
}

/**
 *
 * @param {*} config Set configurations like animation speed
 */
const transitionAnimations = (config = defaultConfig) => {
  const scrollElements = document.querySelectorAll('.scroll-animation')
  const loadElements = document.querySelectorAll('.load-animation')

  const triggerScrollAnimations = () => {
    scrollElements.forEach(element => {
      if (typeof window !== 'undefined') {
        const viewportBottom = window.scrollY + window.innerHeight
        const distanceFromTop = element.offsetTop
        const elHalfHeight = element.offsetHeight / 3
        const elMiddle = distanceFromTop + elHalfHeight

        if (viewportBottom > elMiddle) {
          element.classList.add('active-animation')
        }
      }
    })
  }

  const triggerLoadAnimations = speed => {
    loadElements.forEach(element => {
      setTimeout(() => {
        element.classList.add('active-animation')
      }, speed)
    })
  }

  // Checks animation type and triggers animations accordingly
  const checkAnimations = type => {
    switch (type) {
      case 'scroll':
        triggerScrollAnimations()
        break
      case 'load':
        triggerLoadAnimations()
        break
      default:
        break
    }
  }

  checkAnimations('load', config.load.speed)
  window.addEventListener(
    'scroll',
    throttle(200, () => {
      // Throttled function
      checkAnimations('scroll', config.scroll.speed)
    })
  )
}

export default transitionAnimations
