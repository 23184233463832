import React from 'react'
import PropTypes from 'prop-types'

const LinkedInIcon = props => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 36.099999 36"
    xmlSpace="preserve"
    width="25.099998"
    height="25"
    className={props.wrapperCssClass}
  >
    <defs id="defs11" />
    <sodipodiNamedview
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1"
      objecttolerance="10"
      gridtolerance="10"
      guidetolerance="10"
      id="namedview9"
      showgrid="false"
    />
    <g id="g6">
      <g id="g4">
        <path
          className={props.cssClass}
          d="M 33.3,0 H 2.7 C 1.2,0 0,1.2 0,2.6 V 33.4 C 0,34.8 1.2,36 2.7,36 h 30.7 c 1.5,0 2.7,-1.2 2.7,-2.6 V 2.6 C 36,1.2 34.8,0 33.3,0 Z M 10.7,30.7 H 5.3 V 13.5 h 5.3 V 30.7 Z M 8,11.1 C 6.3,11.1 4.9,9.7 4.9,8 4.9,6.3 6.3,5 8,5 c 1.7,0 3.1,1.4 3.1,3.1 0,1.7 -1.4,3 -3.1,3 z m 22.7,19.6 h -5.3 v -8.4 c 0,-2 0,-4.6 -2.8,-4.6 -2.8,0 -3.2,2.2 -3.2,4.4 v 8.5 H 14 V 13.5 h 5.1 v 2.3 h 0.1 c 0.7,-1.4 2.5,-2.8 5.1,-2.8 5.4,0 6.4,3.6 6.4,8.2 z"
          id="path2"
        />
      </g>
    </g>
  </svg>
)

LinkedInIcon.propTypes = {
  cssClass: PropTypes.string,
  wrapperCssClass: PropTypes.string,
}

export default LinkedInIcon
